var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"pill":"","variant":"light"},on:{"click":_vm.decreaseDayOffset}},[_c('b-icon-chevron-left')],1)],1),_c('b-col',{style:({ textAlign: 'center' })},[_c('b-button-group',{attrs:{"id":"calendarlengthSelection","size":"sm"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":this.length == 1 ? 'dark' : 'secondary'},on:{"click":function($event){return _vm.setCalendarLength(1)}}},[_vm._v("1 Tag")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":this.length == 3 ? 'dark' : 'secondary'},on:{"click":function($event){return _vm.setCalendarLength(3)}}},[_vm._v("3 Tage")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":this.length == 5 ? 'dark' : 'secondary'},on:{"click":function($event){return _vm.setCalendarLength(5)}}},[_vm._v("Woche")])],1),_c('br'),_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.resetDayOffset}},[_vm._v("Heute")])],1),_c('b-col',{style:({ textAlign: 'right' })},[_c('b-button',{attrs:{"pill":"","variant":"light"},on:{"click":_vm.increaseDayOffset}},[_c('b-icon-chevron-right')],1)],1)],1),(_vm.events && !_vm.loading)?_c('b-row',{staticClass:"calendar",attrs:{"no-gutter":""}},[_c('b-col',{staticClass:"pl-0 pr-1",style:({
        display: 'grid',
        gridTemplateRows: `repeat(${_vm.timeLegend.length * 2 - 1}, ${
          160 / 4
        }px)`,
      }),attrs:{"cols":"auto"}},_vm._l((_vm.timeLegend),function(time){return _c('small',{key:time.valueOf(),style:({
          ..._vm.getTimeLegendStyle(time),
          position: 'relative',
          bottom: '-30px',
          width: 'max-content',
          height: 'max-content',
          backgroundColor: '$background',
          zIndex: 0,
        })},[_vm._v(" "+_vm._s(_vm.toLocaleTime(time))+" ")])}),0),_vm._l((_vm.dayOffsetArray),function(day){return _c('b-col',{key:day,staticClass:"p-0"},[_c('CalendarDay',{attrs:{"events":_vm.relevantEvents(day)?.sort(_vm.sortByStartTime),"date":new Date(_vm.startDay.valueOf() + day * _vm.msPerDay).valueOf(),"openingHours":_vm.getOpeningHoursOfDay(day, _vm.startDay),"showHoverEvent":_vm.showHoverEvent},on:{"triggerUpdate":_vm.triggerUpdate,"hoverTerminChange":_vm.onHoverTerminChange,"confirmHoverDate":_vm.onconfirmHoverDate}})],1)})],2):_c('b-row',{attrs:{"align-h":"around"}},[_c('SpinnerLogo')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }