var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Terminübersicht"}},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"90%","html-to-pdf-options":{
      margin: [15, 10],
      filename:
        'Terminübersicht-Patient-' +
        _vm.Rezept?.Kunde?.lastname +
        '-' +
        _vm.Rezept?.id,
    }}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{attrs:{"id":"full-page"}},[_c('div',[_c('img',{attrs:{"id":"leto-logo","src":require("@/assets/img/Leto - Text.png")}}),_c('b-row',[_c('b-col',{staticClass:"anschrift"},[_c('p',[_vm._v(" "+_vm._s(_vm.Rezept?.Kunde?.firstname)+" "+_vm._s(_vm.Rezept?.Kunde?.lastname)+" ")]),_c('p',[_vm._v(_vm._s(_vm.Rezept?.Kunde?.address))]),_c('p',[_vm._v(" Tel: "),_c('a',{attrs:{"href":'tel:' + _vm.Rezept?.Kunde?.phone}},[_vm._v(" "+_vm._s(_vm.Rezept?.Kunde?.phone)+" ")])]),_c('p',[_vm._v(" Email: "),_c('a',{attrs:{"href":'mailto:' + _vm.Rezept?.Kunde?.email}},[_vm._v(" "+_vm._s(_vm.Rezept?.Kunde?.email)+" ")])])]),_c('b-col',{staticClass:"anschrift"},[_c('p',[_vm._v(_vm._s(_vm.Praxis?.name))]),_c('p',[_vm._v(_vm._s(_vm.Praxis?.address))]),_c('p',[_vm._v(" Tel: "),_c('a',{attrs:{"href":'tel:' + _vm.Praxis?.phone}},[_vm._v(_vm._s(_vm.Praxis?.phone))])]),_c('p',[_vm._v(" Email: "),_c('a',{attrs:{"href":'mailto:' + _vm.Praxis?.email}},[_vm._v(" "+_vm._s(_vm.Praxis?.email)+" ")])])])],1),_c('h2',{attrs:{"id":"betreff"}},[_vm._v("Terminübersicht")]),_c('p',[_vm._v("Hallo "+_vm._s(_vm.Rezept?.Kunde?.firstname)+",")]),_c('p',[_vm._v(" du hast folgende Termine bei uns. Bitte erscheine pünktlich und wenn möglich mit Puffer bei uns in der Praxis. ")]),_c('ul',_vm._l((_vm.Rezept?.Termins),function(termin){return _c('li',{key:termin.id},[_vm._v(" "+_vm._s(_vm.dateToLocale(termin.start))+", "+_vm._s(_vm.timeToLocale(termin.start))+" - "+_vm._s(_vm.timeToLocale( new Date(termin.start).setMinutes( new Date(termin.start).getMinutes() + termin.minutes ) ))+" ")])}),0),_c('p',[_vm._v("Wir freuen uns auf deinen Besuch!")]),_c('p',[_vm._v("Mit freundlichen Grüßen")]),_c('p',[_vm._v(_vm._s(_vm.Praxis?.name))])],1),_c('footer',[_c('small',[_vm._v("Automatisch generiert von "),_c('a',{attrs:{"href":"https://leto.app"}},[_vm._v("Leto")]),_vm._v(".")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }